import React, { useState, useContext } from "react";
import { AuthContext } from "../../AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import axios from "axios";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

const GOOGLE_CLIENT_ID = "253523671099-dnj950vf6nuq2jrk4ot0g4fhvbsct2b8.apps.googleusercontent.com";

function Loginnew() {
  const [showPass, setShowPass] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const { login } = useContext(AuthContext);
  const navigate = useNavigate(); // Better navigation approach

  const validateForm = () => {
    let newErrors = {};
    if (!formData.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
      newErrors.email = "Valid Email is required.";
    if (formData.password.length < 6)
      newErrors.password = "Password must be at least 6 characters long.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
        try {
            const response = await axios.post("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/loginuseremail", {
                Email: formData.email,
                Pass: formData.password,
            });

            console.log("Login Response:", response.data); // Debugging log

            if (response.data.success) {
                login(response.data.token);
                localStorage.setItem("userId", response.data.userId); // Ensure consistency
                window.location.href = "/userdashboard";
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error("Login error:", error);
            alert("An error occurred while logging in.");
        }
    }
};

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const { credential } = credentialResponse;
      const response = await axios.post("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/google-login", {
        token: credential,
      });

      if (response.data.success) {
        login(response.data.token);
        localStorage.setItem("userId", response.data.userId); // Store user ID
        window.location.href = "/userdashboard";
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Google login error:", error);
      alert("Google authentication failed.");
    }
  };

  return (
    <section className="account-section">
      <div className="tf-container">
        <Tabs className="wd-form-login tf-tab">
          <h5 style={{ fontSize: "32px" }}>Welcome Back!</h5>
          <p style={{ marginBottom: "20px" }}>Use the following form to login to an existing account.</p>
          <TabList className="menu-tab">
            <Tab className="ct-tab">Existing User</Tab>
            <Tab className="ct-tab">Astrologer</Tab>
          </TabList>
          <div className="content-tab">
            <TabPanel className="inner animation-tab">
              <form onSubmit={handleSubmit}>
                <div className="ip">
                  <label style={{ fontWeight: "bold" }}>Reg. Email address&nbsp;<span>*</span></label>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email Address"
                    style={{ backgroundColor: "#fafafa" }}
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="ip">
                  <label style={{ fontWeight: "bold" }}>Your Password&nbsp;<span>*</span></label>
                  <div className="inputs-group auth-pass-inputgroup">
                    <input
                      type={showPass ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="New Password"
                      className="input-form password-input"
                      style={{ backgroundColor: "#fafafa" }}
                    />
                    <Link className={`password-addon ${showPass ? "icon-eye" : "icon-eye-off"}`} onClick={() => setShowPass(!showPass)} />
                  </div>
                  {errors.password && <p className="error">{errors.password}</p>}
                </div>
                <button type="submit">Login Now</button>
              </form>
              <p className="line-ip" style={{ marginTop: "20px" }}>
                <span>or</span>
              </p>
              <Link to="/otplogin" className="btn-social">Continue with OTP</Link>
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <GoogleLogin onSuccess={handleGoogleSuccess} onError={() => console.log("Login Failed")} />
              </GoogleOAuthProvider>
            </TabPanel>
            
              <div className="group-ant-choice" style={{marginTop:"15px"}}>
                              <div className="sub-ip">
                              
                Not registered yet? <Link to="/registration">Sign Up</Link>
             
                              </div>
                              <Link to="/forgotpassword" className="forgot">
                                Fogot password?
                              </Link>
                            </div>
          </div>
        </Tabs>
      </div>
    </section>
  );
}

export default Loginnew;