import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


Footer.propTypes = {
    
};

function Footer(props) {
    return (
        <footer className="footer" style={{paddingTop:"30px"}}>
              
                <div className="inner-footer" style={{padding: "20px 20px"}}>
                <div className="tf-container">
                    <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="footer-cl-1">
                        <div className="icon-infor d-flex aln-center">
                            <div className="icon">
                            <span className="icon-call-calling"><span className="path1"></span><span className="path2"></span><span
                                className="path3"></span><span className="path4"></span></span>
                            </div>
                            <div className="content">
                            <p>Need help? 24/7</p>
                            <h6><Link to="tel:918960708425">+91-89607-08425</Link></h6>
                            </div>
                        </div>
                        <p>With a profound commitment to
preserving and propagating the essence of Indian sciences, we have embarked on a journey
to foster understanding and appreciation for the rich heritage of astrology, numerology,
medical astrology, and other Indian scientific disciplines.</p>
<div className="wd-social d-flex aln-center"  style={{justifyContent:"unset", marginTop:"15px"}}>
                        <span>Follow Us:</span>
                        <ul className="list-social d-flex aln-center">
                            <li><Link to="https://www.facebook.com/share/1FnrjZh55D/"><i className="icon-facebook"></i></Link></li>
                           
                            <li><Link to="https://x.com/adhigyanam?t=0Eqci1lOokEh6j6LObxilA&s=09"><i className="icon-twitter"></i></Link></li>
                           
                            <li><Link to="https://www.instagram.com/the_vedic_astro?igsh=eXp3NHF0MzRubWw1"><i className="icon-instagram1"></i></Link></li>
                            {/* <li><Link to="#"><i className="icon-youtube"></i></Link></li> */}
                        </ul>
                        </div>
                        
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-6">
                        <div className="footer-cl-2">
                        <h6 className="ft-title">
                            Quick Links
                        </h6>
                        <ul className="navigation-menu-footer">
                            <li> <Link to="/about">About Us</Link> </li>
                            <li> <Link to="#">Consultation</Link> </li>
                            <li> <Link to="#">Horoscopes</Link> </li>
                            <li> <Link to="/userdashboard">My Account</Link> </li>
                            <li> <Link to="/registration">Registration</Link> </li>
                            <li> <Link to="#">Support</Link> </li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6">
                        <div className="footer-cl-3">
                        <h6 className="ft-title">
                            For Our Learners
                        </h6>
                        <ul className="navigation-menu-footer">
                            <li> <Link to="#">Categories</Link> </li>
                            <li> <Link to="#">Bestsellers</Link> </li>
                            <li> <Link to="#">My Courses</Link> </li>
                            <li> <Link to="#">Start Learning</Link> </li>
                            <li> <Link to="#">Favorites</Link> </li>
                            <li> <Link to="#">Free Demos</Link> </li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6">
                        <div className="footer-cl-4">
                        <h6 className="ft-title">
                            From Our Shop
                        </h6>
                        <ul className="navigation-menu-footer">
                            <li> <Link to="#">Categories</Link> </li>
                            <li> <Link to="#">Bestsellers</Link> </li>
                            <li> <Link to="#">Flash Sale</Link> </li>
                            <li> <Link to="#">Upcoming</Link> </li>
                            <li> <Link to="#">My Orders</Link> </li>
                            <li> <Link to="#">Refund Policy</Link> </li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6">
                    <div className="footer-cl-4">
                        <h6 className="ft-title">
                            Astro Consultation
                        </h6>
                        <ul className="navigation-menu-footer">
                            <li> <Link to="#">All Astrologers</Link> </li>
                            <li> <Link to="#">What We Offer</Link> </li>
                            <li> <Link to="#">Astro Services</Link> </li>
                            <li> <Link to="#">Book Appointment</Link> </li>
                            <li> <Link to="#">Feedbacks</Link> </li>
                            <li> <Link to="/registration">Work With Us</Link> </li>
                           
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="bottom">
                <div className="tf-container">
                    <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="bt-left">
                        <div className="copyright">©2025 <b>Sauntesthyam LLP</b>. All Rights Reserved.</div>
                       
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <ul className="menu-bottom d-flex aln-center">
                        <li><Link to="/usageterms">Terms Of Usage</Link> </li>
                        <li><Link to="/privacypolicy">Privacy Policy</Link> </li>
                        <li><Link to="/shippingpolicy">Shipping Policy</Link> </li>
                        <li><Link to="/refundpolicy">Refund Policy</Link> </li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </footer>
    );
}

export default Footer;