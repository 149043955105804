import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);
  
  if (loading) return <div>Loading...</div>; // ⬅ Prevents premature redirect

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;