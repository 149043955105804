import React, { useState } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
import { Link } from "react-router-dom";
import img1 from "../../assets/images/review/thumb2.png";
import shape1 from "../../assets/images/review/shape.png";
import client1 from "../../assets/images/review/client.jpg";
import icon1 from "../../assets/images/review/icon1.png";
import icon2 from "../../assets/images/review/icon2.png";
import icon3 from "../../assets/images/review/icon3.png";

function Review(props) {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  const onVWEnter = () => {
    setViewPortEntered(true);
  };

  return (
    <section className="inner-review-section" style={{paddingTop:"0px", paddingBottom:"0px"}}>
      <div className="tf-container">
        <div className="row">
        <div className="col-lg-4 col-md-12">
        <img src={require ('../../assets/siteimages/greenvedicastro.png')} style={{width:"100%", height:"auto", margin:"auto", padding:"auto", paddingTop:"25px"}} alt="images" />
        </div>

          <div className="col-lg-8 col-md-12">
            <div className="wd-review-job contentbox1 page-text stc" style={{marginLeft:"0px", paddingTop:"25px"}}>
              <h5 style={{marginBottom:"10px"}}>Indian Sciences: A Timeless Wisdom</h5>
              <p>
              Indian sciences, including astrology, numerology, and medical astrology, are far from mere
superstitions; they are deeply embedded in scientific analysis and are integral components
of our cultural heritage. The study of astrology, for instance, seamlessly blends astronomy
and mathematics to unveil the intricate cosmic dance between celestial bodies and earthly
affairs. The gravitational forces of the moon, analogous to other celestial entities, influence
our planet, which, in turn, affects various aspects of human life.

              </p>
              <br/>
              <p>
              Astrology, often criticised as a pseudoscience, deserves a closer look. It is not merely a
prediction tool but a profound study that integrates celestial movements and their effects on
human beings. Contrary to popular belief, astrology is rooted in rigorous mathematical
calculations and observation of planetary positions. These calculations are not arbitrary;
they follow precise mathematical rules that have been refined over centuries.

              </p>
              <br/>
              <p>The principles of astrology are not concocted but meticulously documented in ancient texts
like the Vedas, reflecting the rich legacy of Indian thought and wisdom. Astrology is a holistic
science that examines planetary interactions, lunar phases, and other celestial phenomena
to provide insights into various life events. It's the embodiment of the interplay between
human life and the cosmos, giving rise to a systematic understanding of the universe's
influence on individual experiences.</p>
<br/>


             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Review;
