import React from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";
import Comingsoon from "./pages/sitepages/Comingsoon";
import Home from "./pages/sitepages/Home";
import AboutUs from "./pages/sitepages/AboutUs";

import Error from "./pages/sitepages/Error";
import Registration from "./pages/sitepages/Registration";
import OtpLogin from "./pages/sitepages/OtpLogin";
import DashboardUser from "./pages/sitepages/DashboardUser";
import Login from "./pages/sitepages/Login";
import ForgotPassword from "./pages/sitepages/ForgotPassword";
import ResetPassword from "./pages/sitepages/ResetPassword";

import PrivacyPolicy from "./pages/sitepages/PrivacyPolicy";
import RefundPolicy from "./pages/sitepages/RefundPolicy";
import ShippingPolicy from "./pages/sitepages/ShippingPolicy";
import UsageTerms from "./pages/sitepages/UsageTerms";


import { useEffect } from "react";
import ScrollToTop from "./ScrollToTop";
import { useState } from "react";
import Preloader from "./components/preloader";
import { HelmetProvider } from "react-helmet-async"; 

import ProtectedRoute from "./ProtectedRoute";
import { AuthProvider } from "./AuthContext";

const AppWrapper = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  

  return (
    <HelmetProvider>
      {!loading ? (
        <AuthProvider>
          <Router>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Comingsoon />} />
              <Route path="/home" element={<Home />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/registration" element={<Registration />} />
              <Route path="/otplogin" element={<OtpLogin />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />

              <Route path="/reset-password/:resetToken" element={<ResetPassword />} />

              <Route path="/userdashboard" element={<ProtectedRoute element={<DashboardUser />} />} />

              <Route path="/error" element={<Error />} />
              <Route path="*" element={<Error />} />

              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/refundpolicy" element={<RefundPolicy />} />
              <Route path="/shippingpolicy" element={<ShippingPolicy />} />
              <Route path="/usageterms" element={<UsageTerms />} />
            </Routes>
          </Router>
        </AuthProvider>
      ) : (
        <Preloader />
      )}
    </HelmetProvider>
  );
};


export default AppWrapper;
