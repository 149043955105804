import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import logo from "../../assets/siteimages/logo.png";
import { Link, NavLink } from "react-router-dom";

import axios from "axios";

import { FaSearch } from "react-icons/fa"; // Importing search icon

Header2.propTypes = {};

function Header2({ clname = "", handleMobile }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  }, []);

  const [categories, setCategories] = useState([]);
  const [categoriesnew, setCategoriesnew] = useState([]);
  const [categoriesnew1, setCategoriesnew1] = useState([]);

  useEffect(() => {
    fetchCategories();
    fetchCategoriesnew();
    fetchCategoriesnew1();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/blogcategories"); // Adjust the URL if needed
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching blog categories:", error);
    }
  };

  const fetchCategoriesnew = async () => {
    try {
      const response = await axios.get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/horoscopecategories"); // Adjust the URL if needed
      setCategoriesnew(response.data);
    } catch (error) {
      console.error("Error fetching horoscope categories:", error);
    }
  };

  const fetchCategoriesnew1 = async () => {
    try {
      const response = await axios.get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/astrocategories"); // Adjust the URL if needed
      setCategoriesnew1(response.data);
    } catch (error) {
      console.error("Error fetching astro categories:", error);
    }
  };

  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);

  return (
    <header
      id="header"
      className={`header header-default ${scroll ? "is-fixed is-small" : ""}`}
    >
      <div className="tf-container ct2">
        <div className="row">
          <div className="col-md-12">
            <div className="sticky-area-wrap">
              <div className="header-ct-left">
                <div id="logo" className="logo">
                  <Link to="/home">
                    <img
                      className="site-logo"
                      id="trans-logo"
                      src={logo}
                      alt="Image"
                    />
                  </Link>
                </div>
              </div>
              <div className="header-ct-center">
                <div className="nav-wrap">
                  <nav id="main-nav" className="main-nav">
                    <ul id="menu-primary-menu" className={`menu ${clname}`}>
                      <li className="menu-item">
                        <Link to="/home">Home </Link>
                      </li>
                      {/* <li className="menu-item">
                        <Link to="/about">About Us</Link>
                      </li> */}
                      <li className="menu-item menu-item-has-children">
      <Link to="#">Horoscope</Link>
      <ul className="sub-menu st1">
        {categoriesnew.length > 0 ? (
          categoriesnew.map((categorynew, index) => (
            <li key={index} className="nav-sub subnav2">
              <Link to="#">{categorynew.Named}</Link> {/* 'Named' column from the database */}
            </li>
          ))
        ) : (
          <li className="nav-sub subnav2">
            <span>No categories available</span>
          </li>
        )}
      </ul>
    </li>
    <li className="menu-item menu-item-has-children">
      <Link to="#">Astro Services</Link>
      <ul className="sub-menu st1">
        {categoriesnew1.length > 0 ? (
          categoriesnew1.map((categorynew1, index) => (
            <li key={index} className="nav-sub subnav2">
              <Link to="#">{categorynew1.Named}</Link> {/* 'Named' column from the database */}
            </li>
          ))
        ) : (
          <li className="nav-sub subnav2">
            <span>No categories available</span>
          </li>
        )}
      </ul>
    </li>
                      <li className="menu-item menu-item-has-children">
      <Link to="#">Blog</Link>
      <ul className="sub-menu st1">
        {categories.length > 0 ? (
          categories.map((category, index) => (
            <li key={index} className="nav-sub subnav2">
              <Link to="#">{category.Named}</Link> {/* 'Named' column from the database */}
            </li>
          ))
        ) : (
          <li className="nav-sub subnav2">
            <span>No categories available</span>
          </li>
        )}
      </ul>
    </li>
                      <li className="menu-item menu-item-has-children">
                        <Link to="#">Courses</Link>
                        <ul className="sub-menu st1">
                          
                          {/* <li className="nav-sub subnav2">
                            <Link to="/aboutus">About Us</Link>
                          </li> */}
                         
                        </ul>
                      </li>
                      <li className="menu-item menu-item-has-children">
                        <Link to="#">Shop</Link>
                        <ul className="sub-menu st1">
                          
                          {/* <li className="nav-sub subnav2">
                            <Link to="/aboutus">About Us</Link>
                          </li> */}
                         
                        </ul>
                      </li>
                     
                      <li className="menu-item">
                        <Link to="#">Support</Link>
                      </li>
                     
                     
                      
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="header-ct-right">
              <div className="search-icon" style={{ cursor: "pointer", marginRight: "20px" }}
                  onClick={() => setShowSearch(!showSearch)}>
                  <FaSearch size={20} />
                </div>
                {showSearch && (
                  <div className="search-box" style={{ position: "absolute", top: "80px", background: "white", padding: "10px", borderRadius: "5px", boxShadow: "0px 4px 6px rgba(0,0,0,0.1)", zIndex:99999, width:"100%", right:"0px" }}>
                    
                    <table style={{width:"100%"}}>
                        <tr style={{width:"100%"}}>
                          <td style={{width:"90%"}}> <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search By Keywords.." style={{fontWeight:"bold", color:"black", borderColor:"#f2f2f2",  borderRadius: "4px", backgroundColor:"#f2f2f2"}} /></td>
                          <td style={{width:"10%"}}> <button style={{padding: "11px 32px",
    background:"none",
    backgroundColor:"#14a077",
    borderRadius: "4px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "26px",
    color: "#ffffff",
    borderColor: "transparent",
    textTransform: "capitalize", width:"100%"}} onClick={() => console.log("Searching for:", searchQuery)}>Go</button></td>
                        </tr>
                      </table>
                  </div>
                )}
              <div className="header-customize-item button" style={{padding: "0px 0px",
    background:"none",
    backgroundColor:"#14a077",
    borderRadius: "4px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "26px",
    color: "#ffffff",
    borderColor: "transparent",
    textTransform: "capitalize", marginRight:"6px"}}>
                  <Link to="/" style={{color: "#ffffff"}}>Consult Now</Link>
                </div>
                <div className="header-customize-item button">
                  <Link to="/userdashboard">My Account</Link>
                </div>
              </div>
              <div className="nav-filter" onClick={handleMobile}>
                <div className="nav-mobile">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header2;
