import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Category11 from "../../components/category/Category11";
import { Helmet } from "react-helmet-async";

Comingsoon.propTypes = {};

function Comingsoon(props) {

  return (
    <>
     <Helmet>
                <title>Maintenance Mode - Adhigyanam | Sauntesthyam LLP</title>
              </Helmet>
      <Category11 />
    </>
  );
}

export default Comingsoon;
