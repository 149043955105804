import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import axios from "axios";
import "swiper/css/bundle";

function Testimonials({ className }) {
  const [testimonials, setTestimonials] = useState([]);

  // Fetch data from the MySQL database
  useEffect(() => {
    axios
      .get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/testimonials") // Replace with your actual API URL
      .then((response) => {
        setTestimonials(response.data);
      })
      .catch((error) => {
        console.error("Error fetching testimonials:", error);
      });
  }, []);

  return (
    <section style={{paddingBottom:"20px", paddingTop:"20px"}}>
      <div className="wrap-testimonials over-flow-hidden">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tf-title style-2">
                <div className="group-title">
                  <h1 style={{ fontSize: "30px", marginBottom: "0px" }}>
                    Hear from our satisfied customers!
                  </h1>
                  <p>
                    Read real experiences and insights from those who have benefited from our services. Let their journeys inspire yours!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 wow fadeInUp">
              <Swiper
                modules={[Pagination]}
                spaceBetween={32}
                pagination={{ clickable: true }}
                loop
                slidesPerView={3}
                className="tes-slider"
                breakpoints={{
                  200: { slidesPerView: 1, spaceBetween: 32 },
                  992: { slidesPerView: 2, spaceBetween: 32 },
                  1200: { slidesPerView: 3, spaceBetween: 32 },
                }}
              >
                {testimonials.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="wd-testimonials">
                      <p className="description">{item.Descriptions}</p>
                      <div className="author-group">
                        
                        <div className="infor">
                          <h6>{item.Named}</h6>
                          <div className="position">{item.Jobtitle}</div>
                         
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;