import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

function Category07() {
  
  return (
    <section className="testimonials-category-section" style={{ paddingTop: "30px", paddingBottom:"20px" }}>
      <div className="tf-container">

     
        <div className="row">
          <div className="col-md-12">
            <div className="tf-title style-2">
              <div className="group-title">
                <h1  style={{ marginBottom: "6px", fontSize:"25px" }}>404 Error! Page Not Found.</h1>
                <img
          src={require("../../assets/siteimages/404error.jpg")}
          alt="images" style={{width:"auto", height:"auto", maxHeight:"400px", margin: "auto", padding:"auto", marginTop:"20px", marginBottom:"20px"}}
        />
                <p style={{maxWidth:"650px"}}>
                We are Sorry, But the page you are looking for doesn’t exist on our network.
                It may have been renamed or permanently removed from the system.
                </p>
                <br/>
                <NavLink to="/home" style={{padding: "11px 32px",
    background:"none",
    backgroundColor:"#14a077",
    borderRadius: "4px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "26px",
    color: "#ffffff",
    borderColor: "transparent",
    textTransform: "capitalize", width:"100%"}}>Take Me Home</NavLink>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Category07;