import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function Category08() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/secondarybanners") // Update with actual backend URL
      .then((response) => {
        setData(response.data); // Store response in state
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <section className="testimonials-category-section" style={{ paddingTop: "0px", paddingBottom:"20px" }}>
      <div className="tf-container">
        <div className="row">
          <div className="col-md-12">
            <div className="tf-title style-2">
              <div className="group-title">
                <h1  style={{ marginBottom: "0px", fontSize:"30px" }}>Popular & Trending</h1>
                <p>
                Discover personalized horoscope readings, expert astrology consultations, and in-depth learning courses.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
              {data.map((item, index) => (
                <div key={index} className="col-lg-6 col-md-6 col-sm-12">
                  <div className="group-category-job padding wow fadeInUp" style={{paddingBottom:"10px"}}>
                    <div className="job-category-box" style={{width:"unset", padding:"0px"}}>
                      <div className="job-category-header" style={{marginBottom:"0px"}}>
                      <Link to="#">
                        <img
                          src={item.Path1 || "default-image.jpg"} // Use Path1 for image
                          alt={item.Named}
                          style={{ width: "100%", height: "auto", borderRadius: "10px" }}
                        />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Category08;