import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import axios from "axios";
import "swiper/css";
import { Link } from "react-router-dom";

function Partner() {
  const [partners, setPartners] = useState([]);

  // Fetch data from MySQL
  useEffect(() => {
    axios
      .get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/horoscopecategories") // Replace with your actual API URL
      .then((response) => {
        setPartners(response.data);
      })
      .catch((error) => {
        console.error("Error fetching partners:", error);
      });
  }, []);

  return (
    <section>
      <div className="wd-partner" style={{height:"unset", marginBottom:"30px"}}>


     
          <div className="col-md-12">
            <div className="tf-title style-2">
              <div className="group-title">
                <h1  style={{ marginBottom: "0px", fontSize:"30px",     paddingBottom:"10px" }}>Accurate Horoscope Predictions</h1>
                <p style={{color:"whitesmoke"}}>
                Discover what the stars 🌟 have in store for you!
                </p>
              </div>
            </div>
          </div>

        <div className="tf-container">
       

          {/* <Swiper
            modules={[Autoplay]}
            spaceBetween={68.95}
            slidesPerView={6}
            autoplay={{
              delay: 1,
              disableOnInteraction: true,
            }}
            className="partner-type-6"
            loop={true}
            speed={3000}
            breakpoints={{
              320: { slidesPerView: 2, spaceBetween: 30 },
              500: { slidesPerView: 3 },
              800: { slidesPerView: 4 },
              1200: { slidesPerView: 5 },
              1600: { slidesPerView: 6 },
            }}
          >
            {partners.map((item, index) => (
              <SwiperSlide key={index} style={{marginRight:"10px", maxWidth:"180px", width:"unset"}}>
                <Link to="#" className="logo-partner">
                  <img src={item.Path1 || "default-image.jpg"} alt="horoscope categories" style={{width:"100%", height:"auto", maxWidth:"180px"}} />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper> */}

            <div className="col-md-12">
                      <div className="row">
                        {partners.map((item, index) => (
                          <div key={index} className="col-lg-2 col-md-4 col-4">
                            <div className="group-category-job padding wow fadeInUp" style={{paddingBottom:"10px"}}>
                             
                              
                                  <img
                                    src={item.Path1 || "default-image.jpg"} // Use Path1 for image
                                    alt="horoscope categories"
                                    style={{ width: "100%", height: "auto", borderRadius: "10px", padding:"5px 20px" }}
                                  />
                                 
                               
                              
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
        </div>
      </div>
    </section>
  );
}

export default Partner;