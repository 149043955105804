import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header3 from "../../components/header/Header3";
import Footer2 from "../../components/footer2";
import Gotop from "../../components/gotop";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Collapse } from "react-collapse";
import logo from "../../assets/siteimages/logo.png";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa"; // Import icons

import Breadcrumb from "../../components/breadcrumb";
import { useContext } from "react";
import { AuthContext } from "../../AuthContext";
import "../../assets/FakeTable.css";

DashboardUser.propTypes = {};

function DashboardUser(props) {
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  useEffect(() => {
    const WOW = require("wowjs");
    window.wow = new WOW.WOW({
      live: false,
    });
    window.wow.init();
  }, []);

  const [categories, setCategories] = useState([]);
  const [categoriesnew, setCategoriesnew] = useState([]);
  const [categoriesnew1, setCategoriesnew1] = useState([]);

  useEffect(() => {
    fetchCategories();
    fetchCategoriesnew();
    fetchCategoriesnew1();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/blogcategories"); // Adjust the URL if needed
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching blog categories:", error);
    }
  };

  const fetchCategoriesnew = async () => {
    try {
      const response = await axios.get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/horoscopecategories"); // Adjust the URL if needed
      setCategoriesnew(response.data);
    } catch (error) {
      console.error("Error fetching horoscope categories:", error);
    }
  };

  const fetchCategoriesnew1 = async () => {
    try {
      const response = await axios.get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/astrocategories"); // Adjust the URL if needed
      setCategoriesnew1(response.data);
    } catch (error) {
      console.error("Error fetching astro categories:", error);
    }
  };

const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { logout } = useContext(AuthContext);


  const [regMode, setRegMode] = useState("");
  const [initialFormData, setInitialFormData] = useState({});
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNo: "",
    password: "",
    confirmPassword: "",
    gender: ""
  });
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const storedUserId = localStorage.getItem("userId"); 
        if (!storedUserId) return;
    
        const response = await axios.get(`https://adhigyanam-e92bf1bbbdb1.herokuapp.com/getuserbyid/${storedUserId}`);
        if (response.data) {
          const userData = {
            fullName: response.data.Named || "",
            email: response.data.Email || "",
            contactNo: response.data.Cno || "",
            password: response.data.Pass || "",
            confirmPassword: response.data.Pass || "",
            gender: response.data.Gender || "",
          };
          setFormData(userData);
          setInitialFormData(userData); // Store original data
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
  
    fetchUserData();
  }, []);  // Runs only on component mount
    const [errors, setErrors] = useState({});
  
    const validateForm = () => {
      let newErrors = {};
      if (!formData.fullName.trim()) newErrors.fullName = "Full Name is required.";
      if (!formData.gender.trim()) newErrors.gender = "Gender is required.";
      if (!formData.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
        newErrors.email = "Valid Email is required.";
      if (!/^[6-9]\d{9}$/.test(formData.contactNo))
        newErrors.contactNo = "Enter a valid 10-digit Indian mobile number.";
      if (regMode !== "Google" && regMode !== "Phone" && formData.password.length < 6)
        newErrors.password = "Password must be at least 6 characters long.";
      if (regMode !== "Google" && regMode !== "Phone" && formData.confirmPassword.length < 6)
        newErrors.confirmPassword = "Password must be at least 6 characters long.";
      if (formData.password !== formData.confirmPassword)
        newErrors.confirmPassword = "Passwords do not match.";
    
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
    
      if (name === "contactNo") {
        if (!/^\d*$/.test(value)) return; 
      }
    
      setFormData({ ...formData, [name]: value });
    };

    
    const handleBlur = (event) => {
      const { name, value } = event.target;
      let newErrors = { ...errors };
    
      if (!value) {
        newErrors[name] = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
      } else {
        delete newErrors[name]; // Remove error if the field is valid
      }
    
      setErrors(newErrors);
    };
    const [userId, setUserId] = useState(null);

    useEffect(() => {
      const storedUserId = localStorage.getItem("userId");
      if (storedUserId) {
        setUserId(storedUserId);
      }
    }, []);
  
    useEffect(() => {
      if (userId) {
        fetchUserRegMode(userId);
      }
    }, [userId]);

    const fetchUserRegMode = async (id) => {
      try {
        const response = await axios.get(
          `https://adhigyanam-e92bf1bbbdb1.herokuapp.com/getuserregmode/${id}`
        );
        if (response.data.success) {
          setRegMode(response.data.Regmode);
        } else {
          setRegMode(""); // Fallback to empty if not found
        }
      } catch (error) {
        console.error("Error fetching user registration mode:", error);
        setRegMode(""); // Ensure it doesn't break the UI
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
    
      if (!validateForm()) return; // Prevent submission if invalid
    
      const updatedData = { ...formData, userId }; // Attach userId
    
      try {
        const response = await axios.post(
          "https://adhigyanam-e92bf1bbbdb1.herokuapp.com/updateuserprofile",
          updatedData,
          { headers: { "Content-Type": "application/json" } }
        );
        alert(response.data.message);
        window.location.href = "/userdashboard";
      } catch (error) {
        console.error("Error updating profile:", error);
        alert(error.response?.data?.message || "An error occurred");
      }
    };
    const hasChanges = JSON.stringify(formData) !== JSON.stringify(initialFormData);
    const headers = ["ID", "Dummy Data Title", "Status", "Remarks", "Actions"];
  const placeholderRows = Array(5).fill(["#", "Coming Soon", "Pending", "Dummy Table Records.", "-" ]);
  return (
    <>
    <Helmet>
            <title>Your Dashboard - Adhigyanam | Sauntesthyam LLP</title>
          </Helmet>
          <div className="menu-mobile-popup">
        <div className="modal-menu__backdrop" onClick={handleMobile}></div>
        <div className="widget-filter">
          <div className="mobile-header">
            <div id="logo" className="logo">
              <Link to="/home">
                <img className="site-logo" src={logo} alt="Image" />
              </Link>
            </div>
            <Link className="title-button-group" onClick={handleMobile}>
              <i className="icon-close"></i>
            </Link>
          </div>

          <Tabs className="tf-tab">
            

            <div className="content-tab">
              <TabPanel className="header-ct-center menu-moblie animation-tab">
             
                <div className="nav-wrap">
                  <nav className="main-nav mobile">
                    <ul id="menu-primary-menu" className="menu">
                    <li className="menu-item ">
                        <Link
                          to="/home"
                          className="iteam-menu"
                          
                        >
                          Home
                        </Link>
                      </li>
                      {/* <li className="menu-item ">
                        <Link
                          to="/about"
                          className="iteam-menu"
                          
                        >
                          About Us
                        </Link>
                      </li> */}

                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("horoscope");
                          }}
                        >
                         Horoscope
                        </Link>
                        <Collapse isOpened={toggle.key === "horoscope"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "horoscope" ? "block" : "none"
                              }`,
                            }}
                          >

 {categoriesnew.length > 0 ? (
          categoriesnew.map((categorynew, index) => (
            <li key={index} className="menu-item menu-item-mobile">
              <Link to="#">{categorynew.Named}</Link> {/* 'Named' column from the database */}
            </li>
          ))
        ) : (
          <li className="menu-item menu-item-mobile">
            <span>No categories available</span>
          </li>
        )}

                          </ul>
                        </Collapse>
                      </li>

                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("services");
                          }}
                        >
                          Astro Services
                        </Link>
                        <Collapse isOpened={toggle.key === "services"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "services" ? "block" : "none"
                              }`,
                            }}
                          >

 {categoriesnew1.length > 0 ? (
          categoriesnew1.map((categorynew1, index) => (
            <li key={index} className="menu-item menu-item-mobile">
              <Link to="#">{categorynew1.Named}</Link> {/* 'Named' column from the database */}
            </li>
          ))
        ) : (
          <li className="menu-item menu-item-mobile">
            <span>No categories available</span>
          </li>
        )}

                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("blog");
                          }}
                        >
                          Blog
                        </Link>
                        <Collapse isOpened={toggle.key === "blog"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "blog" ? "block" : "none"
                              }`,
                            }}
                          >

 {categories.length > 0 ? (
          categories.map((category, index) => (
            <li key={index} className="menu-item menu-item-mobile">
              <Link to="#">{category.Named}</Link> {/* 'Named' column from the database */}
            </li>
          ))
        ) : (
          <li className="menu-item menu-item-mobile">
            <span>No categories available</span>
          </li>
        )}

                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("courses");
                          }}
                        >
                          Courses
                        </Link>
                        <Collapse isOpened={toggle.key === "courses"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "courses" ? "block" : "none"
                              }`,
                            }}
                          >
                            {/* <li className="menu-item menu-item-mobile">
                              <Link to="/blog_v1">Blog List </Link>
                            </li>  */}
                            
                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("shop");
                          }}
                        >
                          Shop
                        </Link>
                        <Collapse isOpened={toggle.key === "shop"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "shop" ? "block" : "none"
                              }`,
                            }}
                          >
                            {/* <li className="menu-item menu-item-mobile">
                              <Link to="/blog_v1">Blog List </Link>
                            </li>  */}
                            
                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item ">
                        <Link
                          to="/about"
                          className="iteam-menu"
                          
                        >
                          Who We Are
                        </Link>
                      </li>
                      <li className="menu-item ">
                        <Link
                          to="#"
                          className="iteam-menu"
                          
                        >
                          Support
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </TabPanel>

           
            </div>
          </Tabs>
          <div className="header-customize-item button" style={{padding: "0px 0px",
    background:"none",
    backgroundColor:"#14a077",
    borderRadius: "4px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "21px",
    color: "white",
    borderColor: "transparent",
    textTransform: "capitalize"}}>
            <Link to="/" style={{color: "#ffffff"}}>Consult Now</Link>
          </div>
           
          

          <div className="mobile-footer" style={{marginTop:"0px", marginBottom:"0px", 
    borderTop: "none", borderBottom: "1px solid #e5e5e5", paddingBottom:"0px",
    paddingTop:"15px"}}>
            <div className="icon-infor d-flex aln-center">
              <div className="icon">
                <span className="icon-call-calling">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </span>
              </div>
              <div className="content">
                <p>Need help? 24/7</p>
                <h6>
                  <Link to="tel:918960708425">+91-8960-708425</Link>
                </h6>
              </div>
            </div>
            <div className="wd-social d-flex aln-center">
              <ul className="list-social d-flex aln-center">
                <li>
                  <Link to="https://www.facebook.com/share/1FnrjZh55D/">
                    <i className="icon-facebook"></i>
                  </Link>
                </li>
              
                <li>
                  <Link to="https://x.com/adhigyanam?t=0Eqci1lOokEh6j6LObxilA&s=09">
                    <i className="icon-twitter"></i>
                  </Link>
                </li>
               
                <li>
                  <Link to="https://www.instagram.com/the_vedic_astro?igsh=eXp3NHF0MzRubWw1">
                    <i className="icon-instagram1"></i>
                  </Link>
                </li>
                {/* <li>
                  <Link to="#">
                    <i className="icon-youtube"></i>
                  </Link>
                </li> */}
              </ul>
             
            </div>
          </div>
          <div >
                      <button onClick={logout} className="header-customize-item button" style={{padding: "12px 20px",
    background:"none",
    backgroundColor:"white",
    borderRadius: "4px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "21px",
    color: "black",
    borderColor: "red",
    textTransform: "capitalize"}}>Logout</button>
                    </div>
        </div>
      </div>
 

      
      <Header3 clname="act1" handleMobile={handleMobile} />
        <Breadcrumb title="Dashboard" />
        <section className="account-section" style={{    padding: "30px 0px 0px"}}>
      <div className="tf-container">
      <div className="row" style={{textAlign:"center", marginBottom:"30px"}}>
          <h5 style={{marginBottom:"0px"}}>Welcome To Your Dashboard <span style={{display:"none"}}>{userId}</span></h5>
                <p style={{maxWidth:"900px", margin:"auto", padding:"auto"}}>
                Use the following form and features to manage your profile and access courses, bookings and more.
                </p>
          </div>
          <div className="row">
  <Tabs className="wd-form-login tf-tab" style={{ width: "100%", maxWidth: "unset" }}>
    {/* Scrollable wrapper for mobile */}
    <div
      style={{
        overflowX: window.innerWidth <= 768 ? "auto" : "visible", // Scroll on mobile, full width on laptop
        whiteSpace: "nowrap",
        WebkitOverflowScrolling: "touch",
        maxWidth: "100%",
      }}
    >
      <TabList
        className="menu-tab"
        style={{
          display: "flex",
          gap: "10px",
          flexWrap: "nowrap",
          width: window.innerWidth > 768 ? "100%" : "max-content", // Full width on large screens
          justifyContent: window.innerWidth > 768 ? "space-around" : "flex-start", // Spread tabs on large screens
        }}
      >
        <Tab className="ct-tab" style={{ flex: window.innerWidth > 768 ? "1" : "unset", textAlign: "center" }}>
          Consultations
        </Tab>
        <Tab className="ct-tab" style={{ flex: window.innerWidth > 768 ? "1" : "unset", textAlign: "center" }}>
          Courses
        </Tab>
        <Tab className="ct-tab" style={{ flex: window.innerWidth > 768 ? "1" : "unset", textAlign: "center" }}>
          Products
        </Tab>
        <Tab className="ct-tab" style={{ flex: window.innerWidth > 768 ? "1" : "unset", textAlign: "center" }}>
          Reports
        </Tab>
      </TabList>
    </div>
    <div className="content-tab">
      <TabPanel className="inner animation-tab">
      <div className="table-container">
      <table className="fake-table">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {placeholderRows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
      </TabPanel>
      <TabPanel className="inner animation-tab">
      <div className="table-container">
      <table className="fake-table">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {placeholderRows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
      </TabPanel>
      <TabPanel className="inner animation-tab">
      <div className="table-container">
      <table className="fake-table">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {placeholderRows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
      </TabPanel>
      <TabPanel className="inner animation-tab">
      <div className="table-container">
      <table className="fake-table">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {placeholderRows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
      </TabPanel>
    </div>
  </Tabs>
</div>
      </div>
    </section>
        <section className="checkout-section" style={{paddingTop:"30px", paddingBottom:"0px"}}>
        <div className="tf-container">
       
           {/* Hide the following div if case 2 */}
<div style={{textAlign:"center", backgroundColor:"#fafafa", paddingTop:"20px", border:"solid green 2px", borderBottom:"solid green 2px", borderRadius:"5px"}}>
  <div className="row" style={{maxWidth:"1200px", margin:"auto", padding:"auto"}}>
            <div className="col-lg-12">
              <div className="form-candidate form-checkout form-stc">
                <div className="group-title">
                  <h6 style={{textAlign:"left", fontSize:"22px", marginBottom:"5px"}}>Your Profile Console</h6>
                  <p style={{textAlign:"left"}}><b>Why is this important?</b> To serve you better and to provide you with accurate tailored solutions we request you to please complete your profile before proceeding.</p>
                </div>
                {regMode && (
                <form onSubmit={handleSubmit} style={{textAlign:"left"}}>
                  <div className="group-input" style={{marginBottom:"0px"}}>
                    <div className="ip" style={{marginBottom:"15px"}}>
                      <label style={{float:"left", fontWeight:"bold"}}>Full Name</label>
                      <input type="text" name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      placeholder="Full Name" />
                       {errors.fullName && <p className="error">{errors.fullName}</p>}
                    </div>
                    <div className="ip" style={{marginBottom:"15px"}}>
                      <label  style={{float:"left", fontWeight:"bold"}}>Gender</label>
                      <select
  name="gender"
  value={formData.gender}
  onChange={handleChange}
  onBlur={handleBlur} // Trigger validation when the field loses focus
  className={errors.gender ? "input-error" : ""} // Apply error styling
 style={{backgroundColor:"white", color:"black"}}
>
  <option value="">Select Gender</option>
  <option value="male">Male</option>
  <option value="female">Female</option>
  <option value="other">Prefer Not To Say</option>
</select>

{errors.gender && <span className="error-message">{errors.gender}</span>}
                    </div>
                    <div className="ip" style={{marginBottom:"15px"}}>
                      <label style={{float:"left", fontWeight:"bold"}}>IN Contact No.</label>
                      <input type="text" name="contactNo"
  value={formData.contactNo}
  onChange={handleChange}
  inputMode="numeric"
  pattern="\d*"
  maxLength="10"
  placeholder="Contact No." />
  {errors.contactNo && <p className="error">{errors.contactNo}</p>}
                    </div>
                    <div className="ip" style={{marginBottom:"15px"}}>
                      <label  style={{float:"left", fontWeight:"bold"}}>Email Address</label>
                      <input type="text" name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email Address" />
                      {errors.email && <p className="error">{errors.email}</p>}
                    </div>
                   {/* Hide the password field if Regmode is Google or Phone */}
                   {regMode !== "Google" && regMode !== "Phone" && (
  <div className="ip" style={{ marginBottom: "15px" }}>
    <label style={{ float: "left", fontWeight: "bold" }}>Password</label>
    <input
      type="password"
      name="password"
      value={formData.password}
      onChange={handleChange}
      placeholder="Your Password"
    />
    {errors.password && <p className="error">{errors.password}</p>} 
  </div>
 
)}
{/* Hide the password field if Regmode is Google or Phone */}
{regMode !== "Google" && regMode !== "Phone" && (
  <div className="ip" style={{ marginBottom: "15px" }}>
    <label style={{ float: "left", fontWeight: "bold" }}>Confirm Password</label>
    <input
      type="password"
      name="confirmPassword"
      value={formData.confirmPassword}
      onChange={handleChange}
      placeholder="Confirm Password"
    />
    {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>} 
  </div>
 
)}
                  </div>
                   <div className="group-input-st1">
                   <button 
  type="submit" 
  disabled={!hasChanges} 
  style={{ 
    cursor: !hasChanges ? "not-allowed" : "pointer", 
    opacity: !hasChanges ? 0.6 : 1 
  }}
>
  Update Details
</button>
                  </div> 
                </form>
              )}
              </div>
            </div>
          </div> 

</div>
          {/* Hide the above div if case 2 */}

          <div className="bg-coupon" style={{marginTop:"20px"}}>
        
              <p style={{top:"-15px"}}>
              <b>Keep Your Account Protected</b><br/>
              Use the following button to securely logout from an unknown network and device.
              </p>
              <button onClick={logout} style={{borderColor:"red", marginTop:"15px", border:"solid red 2px", backgroundColor:"white", borderRadius:"5px"}}>Logout</button>
            
           
          </div>
        </div>
      </section>
        
     
      
      <div
       className="floating-buttons"
       style={{
         position: "fixed",
         bottom: "20px",
         left: "20px",
         zIndex: "1000",
         display: "flex",
         flexDirection: "column",
         gap: "10px",
       }}
     >
       {/* Call Button */}
       <a
         href="tel:918960708425"
         style={{
           display: "flex",
           alignItems: "center",
           justifyContent: "center",
           width: "60px",
           height: "60px",
           backgroundColor: "#007BFF",
           borderRadius: "50%",
           boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
           color: "white",
           fontSize: "28px",
         }}
       >
         <FaPhoneAlt />
       </a>
     
       {/* WhatsApp Button */}
       <a
         href="https://wa.me/918960708425"
         target="_blank"
         rel="noopener noreferrer"
         style={{
           display: "flex",
           alignItems: "center",
           justifyContent: "center",
           width: "60px",
           height: "60px",
           backgroundColor: "#25D366",
           borderRadius: "50%",
           boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
           color: "white",
           fontSize: "28px",
         }}
       >
         <FaWhatsapp />
       </a>
     </div>
      <Footer2 />
      <Gotop />
    </>
  );
}

export default DashboardUser;
