import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header2 from "../../components/header/Header2";
import Footer from "../../components/footer";
import Gotop from "../../components/gotop";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Collapse } from "react-collapse";
import logo from "../../assets/siteimages/logo.png";
import axios from "axios";



import Breadcrumb from "../../components/breadcrumb";
import Review from "../../components/aboutPage/Review";

import BannerCounter from "../../components/aboutPage/BannerCounter";


import { Helmet } from "react-helmet-async";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa"; // Import icons

Error.propTypes = {};

function AboutUs(props) {
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  useEffect(() => {
    const WOW = require("wowjs");
    window.wow = new WOW.WOW({
      live: false,
    });
    window.wow.init();
  }, []);

  const [categories, setCategories] = useState([]);
  const [categoriesnew, setCategoriesnew] = useState([]);
  const [categoriesnew1, setCategoriesnew1] = useState([]);

  useEffect(() => {
    fetchCategories();
    fetchCategoriesnew();
    fetchCategoriesnew1();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/blogcategories"); // Adjust the URL if needed
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching blog categories:", error);
    }
  };

  const fetchCategoriesnew = async () => {
    try {
      const response = await axios.get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/horoscopecategories"); // Adjust the URL if needed
      setCategoriesnew(response.data);
    } catch (error) {
      console.error("Error fetching horoscope categories:", error);
    }
  };

  const fetchCategoriesnew1 = async () => {
    try {
      const response = await axios.get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/astrocategories"); // Adjust the URL if needed
      setCategoriesnew1(response.data);
    } catch (error) {
      console.error("Error fetching astro categories:", error);
    }
  };

const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <>
    <Helmet>
            <title>About Us - Adhigyanam | Sauntesthyam LLP</title>
          </Helmet>
      <div className="menu-mobile-popup">
        <div className="modal-menu__backdrop" onClick={handleMobile}></div>
        <div className="widget-filter">
          <div className="mobile-header">
            <div id="logo" className="logo">
              <Link to="/home">
                <img className="site-logo" src={logo} alt="Image" />
              </Link>
            </div>
            <Link className="title-button-group" onClick={handleMobile}>
              <i className="icon-close"></i>
            </Link>
          </div>

          <Tabs className="tf-tab">
            

            <div className="content-tab">
              <TabPanel className="header-ct-center menu-moblie animation-tab">
                <div className="nav-wrap">
                  <nav className="main-nav mobile">
                    <ul id="menu-primary-menu" className="menu">
                    <li className="menu-item ">
                        <Link
                          to="/home"
                          className="iteam-menu"
                          
                        >
                          Home
                        </Link>
                      </li>
                      {/* <li className="menu-item ">
                        <Link
                          to="/about"
                          className="iteam-menu"
                          
                        >
                          About Us
                        </Link>
                      </li> */}

                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("horoscope");
                          }}
                        >
                         Horoscope
                        </Link>
                        <Collapse isOpened={toggle.key === "horoscope"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "horoscope" ? "block" : "none"
                              }`,
                            }}
                          >

 {categoriesnew.length > 0 ? (
          categoriesnew.map((categorynew, index) => (
            <li key={index} className="menu-item menu-item-mobile">
              <Link to="#">{categorynew.Named}</Link> {/* 'Named' column from the database */}
            </li>
          ))
        ) : (
          <li className="menu-item menu-item-mobile">
            <span>No categories available</span>
          </li>
        )}

                          </ul>
                        </Collapse>
                      </li>

                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("services");
                          }}
                        >
                          Astro Services
                        </Link>
                        <Collapse isOpened={toggle.key === "services"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "services" ? "block" : "none"
                              }`,
                            }}
                          >

 {categoriesnew1.length > 0 ? (
          categoriesnew1.map((categorynew1, index) => (
            <li key={index} className="menu-item menu-item-mobile">
              <Link to="#">{categorynew1.Named}</Link> {/* 'Named' column from the database */}
            </li>
          ))
        ) : (
          <li className="menu-item menu-item-mobile">
            <span>No categories available</span>
          </li>
        )}

                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("blog");
                          }}
                        >
                          Blog
                        </Link>
                        <Collapse isOpened={toggle.key === "blog"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "blog" ? "block" : "none"
                              }`,
                            }}
                          >

 {categories.length > 0 ? (
          categories.map((category, index) => (
            <li key={index} className="menu-item menu-item-mobile">
              <Link to="#">{category.Named}</Link> {/* 'Named' column from the database */}
            </li>
          ))
        ) : (
          <li className="menu-item menu-item-mobile">
            <span>No categories available</span>
          </li>
        )}

                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("courses");
                          }}
                        >
                          Courses
                        </Link>
                        <Collapse isOpened={toggle.key === "courses"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "courses" ? "block" : "none"
                              }`,
                            }}
                          >
                            {/* <li className="menu-item menu-item-mobile">
                              <Link to="/blog_v1">Blog List </Link>
                            </li>  */}
                            
                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("shop");
                          }}
                        >
                          Shop
                        </Link>
                        <Collapse isOpened={toggle.key === "shop"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "shop" ? "block" : "none"
                              }`,
                            }}
                          >
                            {/* <li className="menu-item menu-item-mobile">
                              <Link to="/blog_v1">Blog List </Link>
                            </li>  */}
                            
                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item ">
                        <Link
                          to="#"
                          className="iteam-menu"
                          
                        >
                          Support
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </TabPanel>

           
            </div>
          </Tabs>
          <div className="header-customize-item button" style={{padding: "0px 0px",
    background:"none",
    backgroundColor:"#14a077",
    borderRadius: "4px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "26px",
    color: "white",
    borderColor: "transparent",
    textTransform: "capitalize"}}>
            <Link to="/" style={{color: "#ffffff"}}>Consult Now</Link>
          </div>
          <div className="header-customize-item button">
            <Link to="/userdashboard">My Account</Link>
          </div>

          <div className="mobile-footer">
            <div className="icon-infor d-flex aln-center">
              <div className="icon">
                <span className="icon-call-calling">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </span>
              </div>
              <div className="content">
                <p>Need help? 24/7</p>
                <h6>
                   <Link to="tel:918960708425">+91-8960-708425</Link>
                </h6>
              </div>
            </div>
            <div className="wd-social d-flex aln-center">
              <ul className="list-social d-flex aln-center">
                 <li>
                                  <Link to="https://www.facebook.com/share/1FnrjZh55D/">
                                    <i className="icon-facebook"></i>
                                  </Link>
                                </li>
                              
                                <li>
                                  <Link to="https://x.com/adhigyanam?t=0Eqci1lOokEh6j6LObxilA&s=09">
                                    <i className="icon-twitter"></i>
                                  </Link>
                                </li>
                               
                                <li>
                                  <Link to="https://www.instagram.com/the_vedic_astro?igsh=eXp3NHF0MzRubWw1">
                                    <i className="icon-instagram1"></i>
                                  </Link>
                                </li>
                {/* <li>
                  <Link to="#">
                    <i className="icon-youtube"></i>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Header2 clname="act1" handleMobile={handleMobile} />
       
     
      <Breadcrumb title="Sauntesthyam LLP" />
      <Review />
      <section className="wd-iconbox style-3 inner-iconbox-section" style={{paddingTop:"0px"}}>
        <div className="tf-container">
          <div className="title-iconbox style-3 stc">
          <h1  style={{ marginBottom: "6px", fontSize:"30px" }}>Adhigyanam.com</h1>
                <p style={{maxWidth:"900px", margin:"auto", padding:"auto"}}>
                Adhigyanam.com is a distinguished venture that operates under Sauntesthyam LLP , a
registered entity based in Lucknow (Uttar Pradesh). With a profound commitment to
preserving and propagating the essence of Indian sciences, we have embarked on a journey
to foster understanding and appreciation for the rich heritage of astrology, numerology,
medical astrology, and other Indian scientific disciplines.
                </p>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="group-col-3">
               
                <div className="tf-iconbox style-3 cl3 stc2">
                  
                  <div className="box-content">
                    <h6 className="box-title" style={{fontSize:"20px", marginBottom:"10px"}}>
                     Our Mission & Vision
                    </h6>
                    <p>
                    At Adhigyanam.com, our mission is to establish a platform that not only counters the rise of
myths in the realm of astrology but also serves as a beacon of education and awareness for
Indian sciences. We aim to dismantle the misconceptions and inaccuracies surrounding
these revered disciplines, ensuring that individuals are equipped with accurate knowledge
and insights. Our vision is to reclaim the integrity of Indian sciences, enlightening society
about their profound roots in scientific analysis and their valuable contributions to
understanding the interconnectedness of the cosmos and human existence.
                    </p>
                   
                  </div>
                </div>
                <div className="tf-iconbox style-3 cl3 stc2">
                 
                  <div className="box-content ">
                    <h6 className="box-title" style={{fontSize:"20px", marginBottom:"10px"}}>
                    
                        Why We Started?
                     
                    </h6>
                    <p>
                    The surge of myths surrounding astrology, particularly in the aftermath of the COVID-19
pandemic, has raised significant concerns. Hindus lack basic knowledge about reading
Panchang or astrological charts and fail to use them in tackling the prevailing tension and
mental health challenges within society. We felt a moral
obligation to address these malpractices and restore the essence of astrology, numerology,
and other Indian sciences to their rightful place. We understand that these sciences are not
magic or superstition; rather, they are based on rigorous scientific principles and have a
deep-rooted connection with the Vedic wisdom.
                    </p>
                  
                  </div>
                </div>
                <div className="tf-iconbox style-3 cl3 stc2">
                 
                 <div className="box-content">
                   <h6 className="box-title" style={{fontSize:"20px", marginBottom:"10px"}}>
                     Why Join Us?
                   </h6>
                   <p>
                   We stand as an advocate for the revival and education of Indian
sciences, particularly in the domain of astrology & numerology. Weß are driven by the commitment to dispel myths, provide accurate
information, and empower individuals to make informed choices. Webelieve that
these sciences have the power to enhance our understanding of the universe, deepen
our connection with our cultural heritage, and guide us toward a more harmonious existence.
Through US, we aspire to create a society that values authentic knowledge,
respects ancient wisdom, and seeks a holistic understanding of life's intricate tapestry.
                   </p>
                  
                 </div>
               </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BannerCounter />
     
    
     
      <section className="wd-banner-CTA">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-12  wow fadeInUp">
              <div className="group-banner-CTA">
                <h5 style={{color:"white", marginBottom:"6px"}}>Unlock the Wisdom of Astrology</h5>
                <p style={{maxWidth:"900px", margin:"auto", padding:"auto"}}>
                Join us on this transformative journey. Embrace authenticity, enlightenment, and a renewed
connection with the universe. Be a part of our mission at Adhigyanam.com, where wisdom
meets wonder.
                </p>
                <div className="group-btn">
                  <button className="tf-btn">Explore More</button>
                  <button className="tf-btn">Find Astrologers</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
           <div
            className="floating-buttons"
            style={{
              position: "fixed",
              bottom: "20px",
              left: "20px",
              zIndex: "1000",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {/* Call Button */}
            <a
              href="tel:918960708425"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "60px",
                height: "60px",
                backgroundColor: "#007BFF",
                borderRadius: "50%",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                color: "white",
                fontSize: "28px",
              }}
            >
              <FaPhoneAlt />
            </a>
          
            {/* WhatsApp Button */}
            <a
              href="https://wa.me/918960708425"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "60px",
                height: "60px",
                backgroundColor: "#25D366",
                borderRadius: "50%",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                color: "white",
                fontSize: "28px",
              }}
            >
              <FaWhatsapp />
            </a>
          </div>
      <Footer />
      <Gotop />
    </>
  );
}

export default AboutUs;
