import React from 'react';
import PropTypes from 'prop-types';
import WidgetCounter from './WidgetCounter';


ReviewJob.propTypes = {
    
};

function ReviewJob(props) {
    return (
        <section className="review-job-section-two" style={{paddingTop:"30px"}}>
            <div className="tf-container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                    <div className="wd-review-job contentbox1 padding" style={{paddingTop:"0px"}}>
                        <h4 style={{marginBottom:"15px", fontSize:"30px"}}>Unlock the Secrets of Astrology with Adhigyanam</h4>
                        <p style={{marginBottom: "15px"}}>
                        Adhigyanam.com brings you expert astrology services, horoscope consultations, and in-depth astrology courses through video lessons. Whether you’re seeking guidance from top astrologers or learning the ancient wisdom of astrology, we provide a one-stop platform for all your astrological needs.

                        </p>
                        <ul className="wd-list-icon wow fadeInLeft">
                        <li style={{fontSize: "18px",
    marginBottom:"10px"}}>
                            <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd"
                                d="M0 8.79492C0 13.2061 3.5888 16.7949 8 16.7949C12.4112 16.7949 16 13.2061 16 8.79492C16 4.38372 12.4112 0.794922 8 0.794922C3.5888 0.794922 0 4.38372 0 8.79492ZM12.1657 7.36061C12.4781 7.04819 12.4781 6.54166 12.1657 6.22924C11.8533 5.91682 11.3467 5.91682 11.0343 6.22924L7.2 10.0636L5.36569 8.22924C5.05327 7.91682 4.54673 7.91682 4.23431 8.22924C3.9219 8.54166 3.9219 9.04819 4.23431 9.36061L6.63432 11.7606C6.94673 12.073 7.45327 12.073 7.76569 11.7606L12.1657 7.36061Z"
                                fill="#14A077" />
                            </svg> </span>Astrology Consultations – Get personalized insights from experienced astrologers.
                        </li>
                        <li style={{fontSize: "18px",
                            marginBottom:"10px"}}>
                            <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd"
                                d="M0 8.79492C0 13.2061 3.5888 16.7949 8 16.7949C12.4112 16.7949 16 13.2061 16 8.79492C16 4.38372 12.4112 0.794922 8 0.794922C3.5888 0.794922 0 4.38372 0 8.79492ZM12.1657 7.36061C12.4781 7.04819 12.4781 6.54166 12.1657 6.22924C11.8533 5.91682 11.3467 5.91682 11.0343 6.22924L7.2 10.0636L5.36569 8.22924C5.05327 7.91682 4.54673 7.91682 4.23431 8.22924C3.9219 8.54166 3.9219 9.04819 4.23431 9.36061L6.63432 11.7606C6.94673 12.073 7.45327 12.073 7.76569 11.7606L12.1657 7.36061Z"
                                fill="#14A077" />
                            </svg> </span>Horoscope Readings – Know what the stars reveal about your future.
                        </li>
                        <li style={{fontSize: "18px",
    marginBottom:"10px"}}>
                            <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd"
                                d="M0 8.79492C0 13.2061 3.5888 16.7949 8 16.7949C12.4112 16.7949 16 13.2061 16 8.79492C16 4.38372 12.4112 0.794922 8 0.794922C3.5888 0.794922 0 4.38372 0 8.79492ZM12.1657 7.36061C12.4781 7.04819 12.4781 6.54166 12.1657 6.22924C11.8533 5.91682 11.3467 5.91682 11.0343 6.22924L7.2 10.0636L5.36569 8.22924C5.05327 7.91682 4.54673 7.91682 4.23431 8.22924C3.9219 8.54166 3.9219 9.04819 4.23431 9.36061L6.63432 11.7606C6.94673 12.073 7.45327 12.073 7.76569 11.7606L12.1657 7.36061Z"
                                fill="#14A077" />
                            </svg> </span>Astrology Courses – Learn astrology through structured video lessons from experts.
                        </li>
                        <li style={{fontSize: "18px",
    marginBottom:"10px"}}>
                            <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd"
                                d="M0 8.79492C0 13.2061 3.5888 16.7949 8 16.7949C12.4112 16.7949 16 13.2061 16 8.79492C16 4.38372 12.4112 0.794922 8 0.794922C3.5888 0.794922 0 4.38372 0 8.79492ZM12.1657 7.36061C12.4781 7.04819 12.4781 6.54166 12.1657 6.22924C11.8533 5.91682 11.3467 5.91682 11.0343 6.22924L7.2 10.0636L5.36569 8.22924C5.05327 7.91682 4.54673 7.91682 4.23431 8.22924C3.9219 8.54166 3.9219 9.04819 4.23431 9.36061L6.63432 11.7606C6.94673 12.073 7.45327 12.073 7.76569 11.7606L12.1657 7.36061Z"
                                fill="#14A077" />
                            </svg> </span>Vedic Astrology – Explore different astrology methods for accurate predictions.
                            sites
                        </li>
                        <li style={{fontSize: "18px",
    marginBottom:"10px"}}>
                            <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd"
                                d="M0 8.79492C0 13.2061 3.5888 16.7949 8 16.7949C12.4112 16.7949 16 13.2061 16 8.79492C16 4.38372 12.4112 0.794922 8 0.794922C3.5888 0.794922 0 4.38372 0 8.79492ZM12.1657 7.36061C12.4781 7.04819 12.4781 6.54166 12.1657 6.22924C11.8533 5.91682 11.3467 5.91682 11.0343 6.22924L7.2 10.0636L5.36569 8.22924C5.05327 7.91682 4.54673 7.91682 4.23431 8.22924C3.9219 8.54166 3.9219 9.04819 4.23431 9.36061L6.63432 11.7606C6.94673 12.073 7.45327 12.073 7.76569 11.7606L12.1657 7.36061Z"
                                fill="#14A077" />
                            </svg> </span>Remedial Solutions – Receive guidance on rituals, gemstones, and mantras for life’s challenges.
                            changes
                        </li>
                        </ul>
                    </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <WidgetCounter />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ReviewJob;