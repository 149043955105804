import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

function Contact() {
  return (
    <>
      <div>
      <section>
          <div className="tf-container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner-contact-v1" style={{marginTop:"0px", marginBottom:"140px", background:"none", backgroundColor:"#123841", textAlign:"center"}}>
                  <h6 style={{color:"white"}}>Still Wondering? But Knowledge is FREE</h6>
                  <p style={{color:"whitesmoke", margin:"auto", padding:"auto", maxWidth:"600px", marginBottom:"20px"}}>
                    Simply start by selecting a relevant topic to begin a spiritual journey of wisdom and knowledge with Adhigyanam.
                  </p>
                  <Link to="#" className="btn">
                    Start Your Journey!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section   style={{marginBottom:"40px"}}>
          <div className="tf-container">
            <div className="row">
              <div className="col-lg-12">
                <div className="group-contact-us">
                  <div className="infor-contact-v1" style={{background:"none", backgroundColor:"transparent", padding:"0px 20px"}}>
                  <img
          src={require("../../assets/siteimages/fronthelp.png")}
          alt="images" style={{width:"100%", height:"auto", margin: "auto", padding:"auto"}}
        />
                  </div>
                  <div className="form-candidate" style={{padding:"0px 20px", border:"none"}}>
                    <form>
                    <h6 style={{fontWeight: "700",
    fontSize: "24px",
    lineHeight: "30px"}}>Need Help? We are Just a click away!</h6>
                    <p style={{marginBottom:"20px", marginTop:"6px",    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "28px"
}}>Use the following form to raise a quick enquiry with our support executives. We assure to get back to you with a resolution within one Business Day.</p>
                      <div className="group-input">
                     
                        <div className="ip">
                          <input type="text" placeholder="Full Name / Business" />
                        </div>
                        <div className="ip">
                          <input type="text" placeholder="Subject Of Concern" />
                        </div>
                      </div>
                      <div className="group-input">
                     
                        <div className="ip">
                          <input type="text" placeholder="Email Address" />
                        </div>
                        <div className="ip">
                          <input type="text" placeholder="Contact No." />
                        </div>
                      </div>
                      <div className="ip out s1">
                        <input type="email" placeholder="-- Select Support Category --" />
                      </div>
                      <div className="ip out">
                        <textarea placeholder="Your questions..." />
                      </div>
                      <button>send Message</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       
      </div>
    </>
  );
}

export default Contact;
