import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header2 from "../../components/header/Header2";
import Category07 from "../../components/category/Category07";
import Category08 from "../../components/category/Category08";
import Category09 from "../../components/category/Category09";
import ReviewJob from "../../components/jobs/ReviewJob";
import Couter from "../../components/iconbox/Couter";
import Partner from "../../components/partner";
import Footer from "../../components/footer";
import Gotop from "../../components/gotop";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Collapse } from "react-collapse";
import logo from "../../assets/siteimages/logo.png";
import styles from "../../assets/Home.module.css";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Testimonials from "../../components/testimonials";
import Blog01 from "../../components/blog/Blog01";
import Contact from "../../components/contact";
import { Helmet } from "react-helmet-async";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa"; // Import icons

Home.propTypes = {};

function Home(props) {
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  useEffect(() => {
    const WOW = require("wowjs");
    window.wow = new WOW.WOW({
      live: false,
    });
    window.wow.init();
  }, []);

  const [categories, setCategories] = useState([]);
  const [categoriesnew, setCategoriesnew] = useState([]);
  const [categoriesnew1, setCategoriesnew1] = useState([]);

  useEffect(() => {
    fetchCategories();
    fetchCategoriesnew();
    fetchCategoriesnew1();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/blogcategories"); // Adjust the URL if needed
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching blog categories:", error);
    }
  };

  const fetchCategoriesnew = async () => {
    try {
      const response = await axios.get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/horoscopecategories"); // Adjust the URL if needed
      setCategoriesnew(response.data);
    } catch (error) {
      console.error("Error fetching horoscope categories:", error);
    }
  };

  const fetchCategoriesnew1 = async () => {
    try {
      const response = await axios.get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/astrocategories"); // Adjust the URL if needed
      setCategoriesnew1(response.data);
    } catch (error) {
      console.error("Error fetching astro categories:", error);
    }
  };

  const [images, setImages] = useState([]);

  useEffect(() => {
    axios.get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/primarybanners") // Update URL as needed
      .then(response => {
        setImages(response.data);
      })
      .catch(error => console.error("Error fetching images:", error));
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

 
  return (
    <>
    <Helmet>
        <title>Home - Adhigyanam | Sauntesthyam LLP</title>
      </Helmet>
      <div className="menu-mobile-popup">
        <div className="modal-menu__backdrop" onClick={handleMobile}></div>
        <div className="widget-filter">
          <div className="mobile-header">
            <div id="logo" className="logo">
              <Link to="/home">
                <img className="site-logo" src={logo} alt="Image" />
              </Link>
            </div>
            <Link className="title-button-group" onClick={handleMobile}>
              <i className="icon-close"></i>
            </Link>
          </div>

          <Tabs className="tf-tab">
            

            <div className="content-tab">
              <TabPanel className="header-ct-center menu-moblie animation-tab">

             
                <div className="nav-wrap">
                  <nav className="main-nav mobile">



                    <ul id="menu-primary-menu" className="menu">
                    <li className="menu-item ">
                        <Link
                          to="/home"
                          className="iteam-menu"
                          
                        >
                          Home
                        </Link>
                      </li>
                      {/* <li className="menu-item ">
                        <Link
                          to="/about"
                          className="iteam-menu"
                          
                        >
                          About Us
                        </Link>
                      </li> */}

                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("horoscope");
                          }}
                        >
                         Horoscope
                        </Link>
                        <Collapse isOpened={toggle.key === "horoscope"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "horoscope" ? "block" : "none"
                              }`,
                            }}
                          >

 {categoriesnew.length > 0 ? (
          categoriesnew.map((categorynew, index) => (
            <li key={index} className="menu-item menu-item-mobile">
              <Link to="#">{categorynew.Named}</Link> {/* 'Named' column from the database */}
            </li>
          ))
        ) : (
          <li className="menu-item menu-item-mobile">
            <span>No categories available</span>
          </li>
        )}

                          </ul>
                        </Collapse>
                      </li>

                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("services");
                          }}
                        >
                          Astro Services
                        </Link>
                        <Collapse isOpened={toggle.key === "services"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "services" ? "block" : "none"
                              }`,
                            }}
                          >

 {categoriesnew1.length > 0 ? (
          categoriesnew1.map((categorynew1, index) => (
            <li key={index} className="menu-item menu-item-mobile">
              <Link to="#">{categorynew1.Named}</Link> {/* 'Named' column from the database */}
            </li>
          ))
        ) : (
          <li className="menu-item menu-item-mobile">
            <span>No categories available</span>
          </li>
        )}

                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("blog");
                          }}
                        >
                          Blog
                        </Link>
                        <Collapse isOpened={toggle.key === "blog"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "blog" ? "block" : "none"
                              }`,
                            }}
                          >

 {categories.length > 0 ? (
          categories.map((category, index) => (
            <li key={index} className="menu-item menu-item-mobile">
              <Link to="#">{category.Named}</Link> {/* 'Named' column from the database */}
            </li>
          ))
        ) : (
          <li className="menu-item menu-item-mobile">
            <span>No categories available</span>
          </li>
        )}

                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("courses");
                          }}
                        >
                          Courses
                        </Link>
                        <Collapse isOpened={toggle.key === "courses"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "courses" ? "block" : "none"
                              }`,
                            }}
                          >
                            {/* <li className="menu-item menu-item-mobile">
                              <Link to="/blog_v1">Blog List </Link>
                            </li>  */}
                            
                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link
                          to="#"
                          className="iteam-menu"
                          onClick={() => {
                            handleToggle("shop");
                          }}
                        >
                          Shop
                        </Link>
                        <Collapse isOpened={toggle.key === "shop"}>
                          <ul
                            className="sub-menu-mobile"
                            style={{
                              display: `${
                                toggle.key === "shop" ? "block" : "none"
                              }`,
                            }}
                          >
                            {/* <li className="menu-item menu-item-mobile">
                              <Link to="/blog_v1">Blog List </Link>
                            </li>  */}
                            
                          </ul>
                        </Collapse>
                      </li>
                      <li className="menu-item ">
                        <Link
                          to="#"
                          className="iteam-menu"
                          
                        >
                          Support
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </TabPanel>

           
            </div>
          </Tabs>
          <div className="header-customize-item button" style={{padding: "0px 0px",
    background:"none",
    backgroundColor:"#14a077",
    borderRadius: "4px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "26px",
    color: "white",
    borderColor: "transparent",
    textTransform: "capitalize"}}>
            <Link to="/" style={{color: "#ffffff"}}>Consult Now</Link>
          </div>
          <div className="header-customize-item button">
            <Link to="/userdashboard">My Account</Link>
          </div>

          <div className="mobile-footer">
            <div className="icon-infor d-flex aln-center">
              <div className="icon">
                <span className="icon-call-calling">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </span>
              </div>
              <div className="content">
                <p>Need help? 24/7</p>
                <h6>
                  <Link to="tel:918960708425">+91-89607-08425</Link>
                </h6>
              </div>
            </div>
            <div className="wd-social d-flex aln-center">
              <ul className="list-social d-flex aln-center">
                <li>
                  <Link to="https://www.facebook.com/share/1FnrjZh55D/">
                    <i className="icon-facebook"></i>
                  </Link>
                </li>
              
                <li>
                  <Link to="https://x.com/adhigyanam?t=0Eqci1lOokEh6j6LObxilA&s=09">
                    <i className="icon-twitter"></i>
                  </Link>
                </li>
               
                <li>
                  <Link to="https://www.instagram.com/the_vedic_astro?igsh=eXp3NHF0MzRubWw1">
                    <i className="icon-instagram1"></i>
                  </Link>
                </li>
                {/* <li>
                  <Link to="#">
                    <i className="icon-youtube"></i>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Offer Strip */}
      <div className={styles.offerStrip}>
  🎉 Limited Time Offer: Get 30% Off on All Astrology Consultations! 🎉
</div>
      <Header2 clname="act1" handleMobile={handleMobile} />
       
       <div className="slider-container" style={{ width: "100%", margin: "auto", position: "relative" }}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img 
              src={image.Path1 || "default-image.jpg"} 
              alt={`Slide ${index + 1}`} 
              style={{ width: "100%", height: "auto", borderRadius: "10px" }} 
            />
          </div>
        ))}
      </Slider>
    </div>

      <Category07 />
      <Partner />
      <ReviewJob />
      <Category08 />
      
      <Testimonials className="testimonials-section" />
      <Blog01 className="news-section" />
      <Category09 />
      
      <Contact />

      
      <div className="container">
        <Couter className="background1" />
      </div>

      <div
  className="floating-buttons"
  style={{
    position: "fixed",
    bottom: "20px",
    left: "20px",
    zIndex: "1000",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  }}
>
  {/* Call Button */}
  <a
    href="tel:918960708425"
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "60px",
      height: "60px",
      backgroundColor: "#007BFF",
      borderRadius: "50%",
      boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
      color: "white",
      fontSize: "28px",
    }}
  >
    <FaPhoneAlt />
  </a>

  {/* WhatsApp Button */}
  <a
    href="https://wa.me/918960708425"
    target="_blank"
    rel="noopener noreferrer"
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "60px",
      height: "60px",
      backgroundColor: "#25D366",
      borderRadius: "50%",
      boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
      color: "white",
      fontSize: "28px",
    }}
  >
    <FaWhatsapp />
  </a>
</div>
      <Footer />
      <Gotop />
    </>
  );
}
// Custom left arrow
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <FaChevronLeft 
      className="custom-arrow custom-prev-arrow"
      onClick={onClick}
      style={{
        position: "absolute",
        left: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        fontSize: "30px",
        color: "#fff",
        cursor: "pointer",
        zIndex: 1000,
        background: "rgba(0,0,0,0.5)",
        borderRadius: "50%",
        padding: "5px",
      }}
    />
  );
};

// Custom right arrow
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <FaChevronRight 
      className="custom-arrow custom-next-arrow"
      onClick={onClick}
      style={{
        position: "absolute",
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        fontSize: "30px",
        color: "#fff",
        cursor: "pointer",
        zIndex: 1000,
        background: "rgba(0,0,0,0.5)",
        borderRadius: "50%",
        padding: "5px",
      }}
    />
  );
};
export default Home;
