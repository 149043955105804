import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

function Category07() {
  
  return (
    <section className="testimonials-category-section" style={{ paddingTop: "30px", paddingBottom:"20px" }}>
      <div className="tf-container">

      

        <div className="row">
          <div className="col-md-12">
            <div className="tf-title style-2">
              <div className="group-title">
                
                <img
          src={require("../../assets/siteimages/webmaintenance.png")}
          alt="images" style={{width:"auto", height:"auto", maxHeight:"400px", margin: "auto", padding:"auto", marginTop:"20px", marginBottom:"20px"}}
        />
        <h1  style={{ marginBottom: "12px", fontSize:"25px", lineHeight:"32px", maxWidth:"650px" }}>Our website is currently under maintenance. Your patience is highly appreciated!</h1>
                <p style={{maxWidth:"650px"}}>
                Our team have been spending extended periods to bring up our new website. We have been upgrading features and services for our beloved customers and website visitors.
                </p>
                <br/>
                <NavLink to="#" style={{padding: "11px 32px",
    background:"none",
    backgroundColor:"#14a077",
    borderRadius: "4px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "26px",
    color: "#ffffff",
    borderColor: "transparent",
    textTransform: "capitalize", width:"100%"}}>Stay Tuned! Coming Soon</NavLink>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Category07;