import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function Blog01({ className }) {
  const [blogs, setBlogs] = useState([]);

  // Fetch data from MySQL
  useEffect(() => {
    axios
      .get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/blogs") // Replace with your actual API URL
      .then((response) => {
        setBlogs(response.data);
      })
      .catch((error) => {
        console.error("Error fetching blog data:", error);
      });
  }, []);

  return (
    <section style={{ paddingTop: "10px" }}>
      <div className="tf-container">
        <div className="row">
          <div className="col-md-12">
            <div className="tf-title style-2 style-4">
              <div className="group-title">
                <h1 style={{ fontSize: "30px", marginBottom: "0px" }}>For Our Readers</h1>
                <p>Stay connected with the wisdom of astrology! Explore our official blog for insightful articles on horoscope predictions, Vedic astrology, planetary influences, and spiritual guidance.</p>
              </div>
            </div>
          </div>
          {blogs.slice(0, 3).map((blog, index) => (
            <div key={index} className="col-lg-4 wow fadeInUp">
              <div className="box-latest">
                <div className="img-latest">
                  <img
                    src={blog.Path1 || "default-image.jpg"} // Replace with an actual default image
                    alt={blog.Title}
                  />
                </div>
                <div className="box-content">
                  <div className="heading">
                    <Link to="#" className="tag">
                      {blog.Bname} {/* Category */}
                    </Link>
                    <h3>
                      <Link to="#"  style={{fontSize:"18px", lineHeight:"21px"}}>{blog.Title}</Link>
                    </h3>
                  </div>
                  <p>{blog.Sdesc}</p> {/* Short description */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Blog01;