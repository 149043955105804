import React, { useState } from "react";
import { AuthContext } from "../../AuthContext";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import axios from "axios";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useContext } from "react";



const GOOGLE_CLIENT_ID = "253523671099-dnj950vf6nuq2jrk4ot0g4fhvbsct2b8.apps.googleusercontent.com";

function SignUp() {
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNo: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let newErrors = {};
    if (!formData.fullName.trim()) newErrors.fullName = "Full Name is required.";
    if (!formData.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
      newErrors.email = "Valid Email is required.";
    if (!/^[6-9]\d{9}$/.test(formData.contactNo))
      newErrors.contactNo = "Enter a valid 10-digit Indian mobile number.";
    if (formData.password.length < 6)
      newErrors.password = "Password must be at least 6 characters long.";
    if (formData.password !== formData.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "contactNo") {
      if (!/^\d*$/.test(value)) return; 
    }
  
    setFormData({ ...formData, [name]: value });
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isChecked) {
        alert("Please agree to the Terms of Service and Privacy Policy before proceeding.");
        return;
    }

    if (validateForm()) {
      try {
        const submissionData = {
          Named: formData.fullName,
          Email: formData.email,
          Cno: formData.contactNo,
          Jdate: new Date().toISOString().split("T")[0], 
          Regmode: "Email",
          Pass: formData.password,
        };
  
        const response = await axios.post(
          "https://adhigyanam-e92bf1bbbdb1.herokuapp.com/registeruseremail",
          submissionData
        );
  
        if (response.data.success) {
          login(response.data.token);
          localStorage.setItem("userId", response.data.userId);
          window.location.href = "/userdashboard";
       
          setFormData({
            fullName: "",
            email: "",
            contactNo: "",
            password: "",
            confirmPassword: "",
          });
          setIsChecked(false); // Reset checkbox
        } else {
          alert(response.data.message);
        }
  
      } catch (error) {
        console.error("Error submitting form:", error);
        alert("An error occurred while submitting the form.");
      }
    }
};
  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const { credential } = credentialResponse;
      
      const response = await axios.post("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/google-login", { token: credential });

      if (response.data.success) {
        
        login(response.data.token);
        localStorage.setItem("userId", response.data.userId); // Store user ID
        window.location.href = "/userdashboard";
   
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Google login error:", error);
      alert("Google authentication failed.");
    }
  };
  const { login } = useContext(AuthContext);
  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <Tabs className="wd-form-login tf-tab">
          <h5 style={{fontSize:"32px"}}>Welcome To Adhigyanam</h5>
          <p style={{marginBottom:"20px"}}>Use the following form to create a new account with us.</p>
            <TabList className="menu-tab">
              <Tab className="ct-tab">New User</Tab>
              <Tab className="ct-tab">Astrologer</Tab>
            </TabList>
            <div className="content-tab">
              <TabPanel className="inner animation-tab">
              <Link to="/otplogin" className="btn-social">
                                  Continue with OTP
                                </Link>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <GoogleLogin onSuccess={handleGoogleSuccess} onError={() => console.log("Login Failed")} />
    </GoogleOAuthProvider>
                                <p className="line-ip" style={{marginTop:"20px"}}>
                                  <span>or</span>
                                </p>
              <form onSubmit={handleSubmit}>
                  <div className="ip">
                    <label style={{ fontWeight: "bold" }}>Full Name&nbsp;<span>*</span></label>
                    <input
                      type="text"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      placeholder="Full Name"
                      style={{ backgroundColor: "#fafafa" }}
                    />
                    {errors.fullName && <p className="error">{errors.fullName}</p>}
                  </div>

                  <div className="ip">
                    <label style={{ fontWeight: "bold" }}>Email address&nbsp;<span>*</span></label>
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email Address"
                      style={{ backgroundColor: "#fafafa" }}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>

                  <div className="ip">
                    <label style={{ fontWeight: "bold" }}>Contact No.&nbsp;<span>*</span></label>
                    <input
  type="text"
  name="contactNo"
  value={formData.contactNo}
  onChange={handleChange}
  inputMode="numeric"
  pattern="\d*"
  maxLength="10"
  placeholder="Contact No."
  style={{ backgroundColor: "#fafafa" }}
/>
                    {errors.contactNo && <p className="error">{errors.contactNo}</p>}
                  </div>

                  <div className="ip">
                    <label style={{ fontWeight: "bold" }}>Create Password&nbsp;<span>*</span></label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={showPass ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="New Password"
                        className="input-form password-input"
                        style={{ backgroundColor: "#fafafa" }}
                      />
                      <Link
                        className={`password-addon ${showPass ? "icon-eye" : "icon-eye-off"}`}
                        onClick={() => setShowPass(!showPass)}
                      />
                    </div>
                    {errors.password && <p className="error">{errors.password}</p>}
                  </div>

                  <div className="ip">
                    <label style={{ fontWeight: "bold" }}>Confirm Password&nbsp;<span>*</span></label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={showPass2 ? "text" : "password"}
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        placeholder="Confirm Password"
                        className="input-form password-input"
                        style={{ backgroundColor: "#fafafa" }}
                      />
                      <Link
                        className={`password-addon ${showPass2 ? "icon-eye" : "icon-eye-off"}`}
                        onClick={() => setShowPass2(!showPass2)}
                      />
                    </div>
                    {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
                  </div>
                  <div className="group-ant-choice" style={{marginBottom:"0px"}}>
                <div className="sub-ip">
                <input
  type="checkbox"
  checked={isChecked}
  onChange={(e) => setIsChecked(e.target.checked)}
/> 
                  By clicking 'Sign Up' you agree to adhigyanam.com's <Link to="/usageterms">Terms Of Service</Link> and <Link to="/privacypolicy">Privacy Policy</Link>. 
                </div>
              </div>
                  <button type="submit">Sign Up</button>
                   <div className="group-ant-choice" style={{marginTop:"15px"}}>
                                                <div className="sub-ip">
                                                
                                  Registered Already? <Link to="/login">Login Now</Link>
                               
                                                </div>
                                                <Link to="/otplogin" className="forgot">
                                                  Login with Mobile No.
                                                </Link>
                                              </div>
                </form>
           
              </TabPanel>
              <TabPanel className="inner animation-tab">
            
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
}

export default SignUp;
