import React, { useState } from "react";
import { AuthContext } from "../../AuthContext";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import axios from "axios";
import { useContext } from "react";


function SignUpOtp() {
  const [formData, setFormData] = useState({ contactNo: "", otp: "" });
  const [errors, setErrors] = useState({});
  const [otpSent, setOtpSent] = useState(false);

  const [resendTimer, setResendTimer] = useState(60); // 60 seconds countdown
  const [isResendActive, setIsResendActive] = useState(false);

  const validateForm = () => {
    let newErrors = {};
    if (!formData.contactNo.trim()) newErrors.contactNo = "Contact No. is required.";
    if (!/^[6-9]\d{9}$/.test(formData.contactNo))
      newErrors.contactNo = "Enter a valid 10-digit Indian mobile number.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "contactNo" && !/^\d*$/.test(value)) return; // Prevents non-numeric input
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    let timer;
    if (otpSent && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setIsResendActive(true);
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [otpSent, resendTimer]);


  const sendOtp = async (e) => {
    e.preventDefault();
    if (!/^[6-9]\d{9}$/.test(formData.contactNo)) {
      setErrors({ contactNo: "Enter a valid 10-digit Indian mobile number." });
      return;
    }

    try {
      const response = await axios.post("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/sendotp", {
        contactNo: formData.contactNo,
      });

      if (response.data.success) {
        alert("OTP Sent Successfully!");
        setOtpSent(true);
        setIsResendActive(false);
        setResendTimer(60); // Restart timer
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("An error occurred while sending OTP.");
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
  
    if (!formData.otp) {
      setErrors({ otp: "OTP is required." });
      return;
    }
  
    try {
      const response = await axios.post("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/verifyotp", {
        contactNo: formData.contactNo,
        otp: formData.otp,
      });
  
      if (response.data.success) {
        // alert(response.data.message);
        login(response.data.token);
        localStorage.setItem("userId", response.data.userId);
        window.location.href = "/userdashboard";
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert("An error occurred while verifying OTP.");
    }
  };
  
const { login } = useContext(AuthContext);
  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <Tabs className="wd-form-login tf-tab">
            <h5 style={{fontSize:"32px"}}>Welcome To Adhigyanam</h5>
            <p style={{marginBottom:"20px"}}>Use the following form to create a new account or login to an existing account.</p>
            <TabList className="menu-tab">
              <Tab className="ct-tab" style={{width:"100%"}}>Continue via OTP</Tab>
            </TabList>
            <div className="content-tab">
              <TabPanel className="inner animation-tab">
             
              {!otpSent ? (
          <form onSubmit={sendOtp}>
          <div className="ip">
          <label style={{ fontWeight: "bold" }}>IN Contact No&nbsp;<span>*</span></label>
            <input
              type="text"
              name="contactNo"
              value={formData.contactNo}
              onChange={handleChange}
              maxLength="10"
              placeholder="Enter Your Contact No." 
              style={{ backgroundColor: "#fafafa" }}
            />
            {errors.contactNo && <p className="error">{errors.contactNo}</p>}
            </div>
            <button type="submit" style={{marginTop:"0px"}}>Send OTP</button>
          </form>
        ) : (
          <form onSubmit={verifyOtp}>
          <div className="ip">
          <label style={{ fontWeight: "bold" }}>Enter OTP&nbsp;<span>*</span></label>
            <input
              type="text"
              name="otp"
              value={formData.otp}
              onChange={handleChange}
              maxLength="6"
              placeholder="Please Enter OTP" 
              style={{ backgroundColor: "#fafafa" }}
            />
            {errors.otp && <p className="error">{errors.otp}</p>}
            </div>
            <button type="submit"  style={{marginTop:"0px"}}>Verify OTP</button>
             {/* Resend OTP Section */}
             <p style={{marginTop:"5px"}}>
                {isResendActive ? (
                  <button onClick={sendOtp} style={{ background: "none", border: "none", color: "green", cursor: "pointer", border: "solid green thin" }}>
                    Resend OTP
                  </button>
                ) : (
                  <span>Resend OTP in {resendTimer}s</span>
                )}
              </p>
          </form>
        )}
           
              </TabPanel>
              
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
}

export default SignUpOtp;
