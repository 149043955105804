import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function Category07() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://adhigyanam-e92bf1bbbdb1.herokuapp.com/astrocategories") // Update with actual backend URL
      .then((response) => {
        setData(response.data); // Store response in state
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <section className="testimonials-category-section" style={{ paddingTop: "20px", paddingBottom:"20px" }}>
      <div className="tf-container">

     

        <div className="row">
          <div className="col-md-12">
            <div className="tf-title style-2">
              <div className="group-title">
                <h1  style={{ marginBottom: "0px", fontSize:"30px" }}>Explore Astro Services</h1>
                <p>
                  Designed to guide you on your spiritual journey
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
              {data.map((item, index) => (
                <div key={index} className="col-lg-2 col-md-4 col-6">
                  <div className="group-category-job padding wow fadeInUp" style={{paddingBottom:"10px"}}>
                    <div className="job-category-box" style={{width:"unset", padding:"18px"}}>
                      <div className="job-category-header" style={{marginBottom:"0px"}}>
                        <img
                          src={item.Path1 || "default-image.jpg"} // Use Path1 for image
                          alt={item.Named}
                          style={{ width: "100%", height: "auto", borderRadius: "10px" }}
                        />
                        <h1 style={{marginTop:"0px", fontWeight: "600", 
    fontSize:"16px"}}>
                          <Link to="/categoryid">{item.Named}</Link>
                        </h1>
                      </div>
                      <Link to="/categoryid" className="btn-category-job">
                        Learn More
                        <span className="icon-keyboard_arrow_right"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Category07;